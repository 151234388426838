
<template>
<!--single root!-->
<div>
    <!--body!-->
    <div class="register_area bg3" >
        <div class="container" style="margin-top:0px!important;background-size:cover">
            <div class="row">
                <div class="col-sm-12">
                    <div class="center reg-title">
                        <h2 v-if="type==1">{{$t('welcomeBack')}}</h2>
                        <h2 v-else-if="type==2">{{$t('welcomeJoin')}}</h2>
                    </div>
                    <ul class="tabs">
                        <li class="ju al"><a @click="switchTab($event,1)" href="#" id="1">{{$t("login")}}</a></li>
                        <li class="line"></li>
                        <li class="ju al"><a @click="switchTab($event,2)" href="#" id="2">{{$t("register")}}</a></li>
                    </ul>
                </div>
                <div class="col-sm-12">
                    <!-- 登錄 -->
                    <div class="form row" id="login" v-show="type==1">
                        <div class="col-sm-12 container">
                                                <b style="color:red;">{{$t("accountUse")}}</b>
                            <br/>
                            <div class="input">
                                <input class="main-form animated bounceInDown" id="loginUsername" type="text" name="usrname" v-model="user" :placeholder="$t('yourUsername')"/>
                            </div>
                            <div class="input">
                                <input class="main-form animated bounceInDown" id="loginPW" type="password" name="password" v-model="pwd" :placeholder="$t('yourPwd')"/>
                            </div>
                            <div class="input relation">
                                <div class="btnLoad" v-if="load"></div>
                                <input @click="login" class="main-form btn animated bounceInDown op" type="button" name="usrname" :value="$t('login')"/>
                            </div>
                            <div class="input al">
                                <label class="pull-left al">
                                    <input type="checkbox" v-model="keep"><span style="color:white">{{$t("keepPwd")}}</span>
                                </label>
                               <!-- <label class="pull-right">
                                    <a href="#" id="3" class="forget-password">{{$t("forgot")}}</a>
                                </label>!-->
                            </div>
                        </div>
                    </div>
                    <!-- 註冊 -->
                    <div class="form row" id="register" v-show="type==2">
                        <div class="col-sm-12">
                            <br/> 
                                  <b style="color:red;">{{$t("accountUse")}}</b>
                            <div class="input">
                                <input class="main-form animated bounceInDown" v-model="reg.user" id="regUsername" type="text" name="name" :placeholder="$t('yourUsername')"/>
                            </div>
                            <!-- <div class="input">
                                <input class="main-form animated bounceInDown" v-model="reg.email" id="regEmail" type="text" name="usrname" placeholder=" 你的郵箱..."/>
                            </div> -->
                            <div class="input">
                                <input class="main-form animated bounceInDown" v-model="reg.pwd" id="regPW" type="password" name="password" :placeholder="$t('yourPwd')"/>
                            </div>
                            <div class="input">
                                <input class="main-form animated bounceInDown" v-model="reg.phone" type="text" name="password"  :placeholder="$t('yourPhone')" />
                            </div>
                            <div class="input relation">
                                <div class="btnLoad" v-if="load"></div>
                                <input @click="regFn" class="main-form btn animated bounceInDown" type="button" :value="$t('register')"/>
                            </div>
                        </div>
                    </div>
                    <!-- 忘記密碼 -->
                    <div class="form row" id="forgetP"  v-show="type==3">
                        <div class="col-sm-12 container">
                            <div class="input">
                                <p>To reset your password enter your email we'll send you a link to reset your password.</p>
                            </div>
                            <div class="input">
                                <input class="main-form animated bounceInDown" type="text" name="usrname" placeholder=" EMAIL ADDRESS"/>
                            </div>
                            <div class="input relation">
                                <div class="btnLoad" v-if="load"></div>
                                <input class="main-form btn reset animated bounceInDown" type="button" name="" value="RESET PASSWORD"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>



<script>
/* eslint-disable */
export default {
    name: 'register',
    data () {
        return {
            msg: "歡迎您回來船長哥哥！",
            user: "",
            pwd: "",
            keep: false,
            reg: {
                user:"",
                pwd:"",
                email:"",
                phone:""
            },
            type: 1,    // 1登錄, 2註冊, 3 忘記密碼
            load: false,
        }
    },
    created () {
        let user = localStorage.getItem("user")
        if (user) {
            this.user = user
            this.pwd = localStorage.getItem("pwd")
        }
        this.msg = this.$t("welcomeBack")
    },
    methods: {
        //switch between login and sign up
        switchTab(event,type) {
            this.type = type
            console.log(event.currentTarget.id)
            let id = event.currentTarget.id;

            if(id == 2){
                    this.msg = this.$t("welcomeJoin")
                    // $("#register").css("display","block");
                    // $("#login").css("display","none");
            }
            else{
                this.msg = this.$t("welcomeBack")
                // $("#register").css("display","none");
                // $("#forgetP").css("display","none");
                // $("#login").css("display","block");
            }
        },
        //login button, trigger btn
        login(){
            if (!this.user || !this.pwd) {
                this.$Swal.fire({
                    // title: '請輸入賬號和密碼!',
                    text: this.$t('enterUserPwd'),   // '請輸入賬號和密碼',
                    icon: 'warning',
                    confirmButtonText: this.$t("confirm")
                })
            } else {
                this.load = true
                let data = {
                    username: this.user,
                    password: this.pwd
                }
                this.$http.post("login",data).then(res => {
                    console.log(res)
                    this.load = false
                    if (res.data.code == 200) {
                        localStorage.setItem("JWT", res.data.data.token)
                        localStorage.setItem("user_id", res.data.data.user_id)
                        this.$router.push({
                            name: "quickBook"
                        })
                        this.$store.commit("setState", { key: "login",value:true })
                        this.$store.commit("setState", { key: "user_id",value:res.data.data.user_id })
                        this.$store.commit("setState", { key: "username",value:res.data.data.username })

                        if (this.keep) {
                            localStorage.setItem("user",this.user)
                            localStorage.setItem("pwd",this.pwd)
                        }
                    } else {
                        this.$Swal.fire({
                            text:res.data.message,
                            confirmButtonText: '確定'
                        })
                    }
                }).catch(() => {
                    this.load = false
                    this.$Swal.fire({
                        icon: "error",
                        text:"網絡錯誤,請稍後再試",
                        confirmButtonText: '確定'
                    })
                })
            }
           

        },
        //reg button, trigger btn
        regFn(){
            if (!this.reg.user) {
                this.$Swal.fire({
                    text: '請輸入用戶名',
                    icon: 'warning',
                    confirmButtonText: '確定'
                })
            } 
            // else if (!this.reg.email) {
            //     this.$Swal.fire({
            //         text: '請輸入郵箱',
            //         icon: 'warning',
            //         confirmButtonText: '確定'
            //     })
            // }
            // else if (!this.reg.email.includes("@")) {
            //     this.$Swal.fire({
            //         text: '郵箱格式錯誤',
            //         icon: 'warning',
            //         confirmButtonText: '確定'
            //     })
            // }
            else if (!this.reg.pwd) {
                this.$Swal.fire({
                    text: '請輸入密碼',
                    icon: 'warning',
                    confirmButtonText: '確定'
                })
            }
            else if (this.reg.pwd.length < 6) {
                this.$Swal.fire({
                    text: '密碼不得少於六位',
                    icon: 'warning',
                    confirmButtonText: '確定'
                })
            }
            else if (!this.reg.phone) {
                this.$Swal.fire({
                    text: '請輸入電話號碼',
                    icon: 'warning',
                    confirmButtonText: '確定'
                })
            } else {
                this.load = true
                let data = {
                    username:this.reg.user,
                    password:this.reg.pwd,
                    phone:this.reg.phone
                }
                this.$http.post('register', data).then((res) => {
                    console.log(res)
                    this.load = false
                    if (res.data.code == 200) {
                        localStorage.setItem("JWT", res.data.data.token)
                        localStorage.setItem("user_id", res.data.data.user_id)
                        this.$router.push("/")
                        this.$store.commit("setState", { key: "login",value:true })
                        this.$store.commit("setState", { key: "user_id",value:res.data.data.user_id })
                        this.$store.commit("setState", { key: "username",value:res.data.data.username })
                        
                    } else {
                        this.$Swal.fire({
                            text:res.data.message,
                            confirmButtonText: '確定'
                        })
                    }
                }).catch(() => {
                    this.load = false
                    this.$Swal.fire({
                        text:"網絡錯誤, 請稍後再試",
                        confirmButtonText: '確定'
                    })
                })
            }


            // let email = $("#regEmail").val();
            // let password = $("#regPW").val();
            // let username = $("#regUsername").val();
            // let phone = $("#phone").val();

            

            
        }
    },
    
}


  

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >

 @media (max-width: 767px) {
  .tabs,
  .form {
    width: 100%!important;
    opacity: 0.8;
    border-radius: 5px;
  }
}
ul {
        padding: 0;
        margin: 0;
    }
    li {
        list-style: none;
    }
.tabs,
.form {
  width: 390px;
  opacity: 0.8;
  border-radius: 5px;
}
.tabs {
  background: #1ab188;
  height: 60px;
  margin: 25px auto 10px;
  color: #000;
}
.form {
  height: 300px;
  margin: 25px auto;
  background: #000;
}
.tabs li {
  width: 38%;
  height: 30px;
  vertical-align: middle;
  float: left;
  margin: 15px;
  padding: 0;
 
}
.line {
    width: 1px !important;
    border-left: #fff solid 1px;
}
.tabs li:last-child {
  border: none;
}
.tabs li a {
  text-decoration: none;
  /* padding: 10px 20px; */
  color: #fff;
  font-size: 18px;
  display: block;
  width: 100%;
  text-align:center;
}
li a:hover {
  color: #fff;
  text-decoration: none;
}
li a:active {
  color: #fff;
  text-decoration: none;
}
li a:link {
  color: #fff;
  text-decoration: none;
}
.container {
  margin-top: 20px;
}
.input {
  margin: 12px 0;
}
input.main-form {
  border: 1px solid #a0b3b0;
  display: block;
  width: 100%;
  height: 40px;
  color: #fff;
  background: #000;
  font-size: 16px;
}
input[type="checkbox"] {
  margin-right: 5px;
  vertical-align: top;
}
.input a {
  color: #fff;
  
}
.form input.btn {
  background: #1ab188;
  border: none;
  cursor: pointer;
}
.btn:hover {
    box-shadow: 0 0 3px #FFF;
}

</style>